@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@font-face {
		font-family: 'Noto Sans SC';
		font-weight: 300;
		src: url('assets/fonts/NotoSansSC-Light.otf');
	}
	@font-face {
		font-family: 'Noto Sans SC';
		font-weight: 400;
		src: url('assets/fonts/NotoSansSC-Regular.otf');
	}
	@font-face {
		font-family: 'Noto Sans SC';
		font-weight: 500;
		src: url('assets/fonts/NotoSansSC-Medium.otf');
	}
	@font-face {
		font-family: 'Noto Sans SC';
		font-weight: 700;
		src: url('assets/fonts/NotoSansSC-Bold.otf');
	}
	@font-face {
		font-family: 'Noto Sans SC';
		font-weight: 900;
		src: url('assets/fonts/NotoSansSC-Black.otf');
	}

	h1 {
		@apply text-2xl;
	}
	h2 {
		@apply text-xl;
	}
	body {
		background: 'white';
		overflow-y: scroll;
	}
}

@keyframes slide {
	100% {
		background-position: 0 0;
	}
	0% {
		background-position: -2729px -1506px;
	}
}

@layer components {
	.bg_home {
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: -100;
		position: fixed;
		background-image: url('https://ethemerals-media.s3.amazonaws.com/bg_home.png');
		-webkit-animation: slide 300s linear infinite;
		animation: slide 300s linear infinite;
		background-repeat: repeat;
	}
	.bg_wilds {
		background-image: url('https://ethemerals-media.s3.amazonaws.com/wilds/worldmap.jpg');
		object-fit: none;
		background-position: center;
	}
	.bg_wilds_hub {
		background-image: url('https://ethemerals-media.s3.amazonaws.com/wilds/homehub.jpg');
	}
	.bg_wilds_land {
		background-image: url('https://ethemerals-media.s3.amazonaws.com/wilds/land.jpg');
	}
	.page_bg {
		/* background-image: linear-gradient(to bottom, #5a6571, #4b5665, #3d4759, #30384d, #242a41); */
		background: linear-gradient(90deg, hsl(186, 33%, 98%) 0%, hsl(212, 33%, 95%) 100%);
		background: -moz-linear-gradient(90deg, hsl(186, 33%, 98%) 0%, hsl(212, 33%, 95%) 100%);
		background: -webkit-linear-gradient(90deg, hsl(186, 33%, 98%) 0%, hsl(212, 33%, 95%) 100%);
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: -100;
		position: fixed;
	}
	.scrollbar_right {
		right: calc(100% - 100vw);
	}
	.scrollbar_pad {
		padding-left: calc(100vw - 100%);
	}
	.nft_details_container {
		width: 1096px;
	}
	.nft_details_bg {
		width: 700px;
		height: 924px;
	}
	.os_nft_details_bg {
		width: 980px;
		height: 1240px;
	}
	.nft_details_img {
		width: 700px;
		height: 800px;
	}
	.os_nft_details_img {
		width: 980px;
		height: 1120px;
	}
	.nft_details_sidebar {
		width: 396px;
		height: 924px;
	}
	.navBg {
		background: #030029; /* fallback for old browsers */
	}
	.titleBg {
		background: #003973; /* fallback for old browsers */
	}
	.menu-active {
		color: #f900d1;
	}
	.vertical-center {
		margin: 0;
		position: absolute;
		top: 50%;
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.center {
		margin: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}

	/* CHART */
	.chart-card {
		text-align: center;
		margin: auto;
		max-width: 300px;
		min-height: 512px;
	}

	.chart-card img {
		width: 44px;
		height: 44px;
	}

	.chart {
		position: relative;
		overflow: hidden;
	}

	.chart svg {
		vertical-align: middle;
		position: absolute;
		-ms-transform: translate(4px, 12px);
		transform: translate(4px, 12px);
	}
}

.bn-onboard-custom.bn-onboard-modal {
	z-index: 50;
}
.bn-onboard-custom.bn-onboard-modal-content {
	border-radius: 1rem /* 16px */;
	color: rgba(229, 231, 235);
	background-color: rgba(19, 31, 49);
}
.bn-onboard-custom.bn-onboard-modal-selected-wallet {
	background-color: #283248;
}
